import { SelectOptionIcon } from 'Types';

export const socialList: SelectOptionIcon[] = [
	{
		value: 'facebook',
		label: 'facebook',
		icon: 'facebook'
	},
	{
		value: 'twitter',
		label: 'twitter',
		icon: 'twitter'
	},
	{
		value: 'instagram',
		label: 'instagram',
		icon: 'instagram'
	},
	{
		value: 'youtube',
		label: 'youtube',
		icon: 'youtube'
	},
	{
		value: 'linkedin',
		label: 'linkedin',
		icon: 'linkedin-in'
	},
	{
		value: 'xing',
		label: 'xing',
		icon: 'xing'
	},
	{
		value: 'tiktok',
		label: 'tiktok',
		icon: 'tiktok'
	}
];

export const featureIconList: SelectOptionIcon[] = [
	{
		value: 'person-chalkboard',
		label: 'person-chalkboard',
		icon: 'person-chalkboard'
	},
	{
		value: 'person-arrow-down-to-line',
		label: 'person-arrow-down-to-line',
		icon: 'person-arrow-down-to-line'
	},
	{
		value: 'earth-europe',
		label: 'earth-europe',
		icon: 'earth-europe'
	},
	{
		value: 'map-location',
		label: 'map-location',
		icon: 'map-location'
	},
	{
		value: 'comments',
		label: 'comments',
		icon: 'comments'
	},
	{
		value: 'screen-users',
		label: 'screen-users',
		icon: 'screen-users'
	},
	{
		value: 'bullseye-arrow',
		label: 'bullseye-arrow',
		icon: 'bullseye-arrow'
	},
	{
		value: 'bullseye-pointer',
		label: 'bullseye-pointer',
		icon: 'bullseye-pointer'
	},
	{
		value: 'laptop-mobile',
		label: 'laptop-mobile',
		icon: 'laptop-mobile'
	},
	{
		value: 'megaphone',
		label: 'megaphone',
		icon: 'megaphone'
	},
	{
		value: 'chart-mixed',
		label: 'chart-mixed',
		icon: 'chart-mixed'
	},
	{
		value: 'arrows-spin',
		label: 'arrows-spin',
		icon: 'arrows-spin'
	},
	{
		value: 'badge-percent',
		label: 'badge-percent',
		icon: 'badge-percent'
	}
];
